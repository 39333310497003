//.....................................................................
// GET SESSION DATA........
// this function is used to get data from session
//.....................................................................
export const getSession = (key) => {
  const session = sessionStorage.getItem(key)
  if (typeof session == 'string') {
    return session
  }
  return JSON.parse(session) || false
}
//.....................................................................
// SET SESSION DATA........
// this function is used to set session data in the session
//.....................................................................

// set the token and user from the session storage
export const setUserSession = (key, value) => {
  if (typeof value == 'string') {
    sessionStorage.setItem(key, value)
  } else {
    sessionStorage.setItem(key, JSON.stringify(value))
  }
}

//.....................................................................
// REMOVE SESSION DATA........
// this function is used to remove session data from browser
//.....................................................................
export const removeUserSession = (key) => {
  sessionStorage.removeItem(key)
}
