import { configureStore } from "@reduxjs/toolkit";
import InventorySlice from "./features/InventorySlice";
import jobSlice from "./features/jobSlice";
import MantainanceSlince from "./features/MantainanceSlince";
import sectionsSlice from "./features/sectionsSlice";
import storeroomSlice from "./features/storeroomSlice";
import TasksSlice from "./features/TasksSlice";
import uiSlice from "./features/uiSlice";
import userSlice from "./features/userSlice";

export const store = configureStore({
  reducer: {
    app_ui: uiSlice,
    // section: sectionsSlice,
    // inventory: InventorySlice,
    // job: jobSlice,
    // maintenance: MantainanceSlince,
    // storeroom: storeroomSlice,
    // tasks: TasksSlice,
    users: userSlice,
  },
});
