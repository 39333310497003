import { createSlice } from "@reduxjs/toolkit";
import {
  creatrecod,
  getrecord,
  get_data_by_id,
  deleterecord,
  updaterecord,
} from "../functions/maintenance.actions";

/**
 * .................................................................
 * MANAGE  INVENTORY PLANNED MAINTENANCE
 * .................................................................
 *
 * This is a module that will manage planned maintenance
 *
 * FEATURES
 *
 * 1.add inventory  planned  maintenance
 * 2.update inventory planned  maintenance
 * 3.delete inventory planned maintenance
 *.................................................................
 */
const initialState = {
  columns: [],
  maitenance: [],
  maintenaceURL: "workshop/workshopsite?",
  isLoading: true,
  openModal: false,
  record: {},
};

const maintainanceSlice = createSlice({
  name: "mantainance",
  initialState,
  reducer: {
    toggleSideBar: (state, action) => {
      state.sidebarShow = !state.sidebarShow;
    },
  },
  extraReducers: {
    [creatrecod.fulfilled]: (state, { payload }) => {},
    [getrecord.fulfilled]: (state, { payload }) => {},
    [get_data_by_id.fulfilled]: (state, { payload }) => {},
    [deleterecord.fulfilled]: (state, { payload }) => {},
    [updaterecord.fulfilled]: (state, { payload }) => {},
  },
});

export const maintenanceActions = maintainanceSlice.actions;
export default maintainanceSlice.reducer;
