import axios from "axios";
import config from "./api_config";

import { getSession } from "./useSessionStorage";

const user = JSON.parse(getSession("user"));
axios.defaults.headers.common["X-Auth-Token"] = user.token;

//.....................................................................
// LOGIN FUNCTION........
// when login is successful create session to store token and user details
//.......................................................................
export async function login(url, params) {
  try {
    let result = await config.post(url, params);
    return result.data;
  } catch (error) {
    return error;
  }
}

//.....................................................................
// call logout table........
// this function will logout user, remove session data from browser
//.......................................................................
export async function logout(url, params) {
  try {
    return await config.post(url, params);
  } catch (error) {
    return error;
  }
}

//.....................................................................
// call login api........
// when login is succeful create session to store token and user details
//.......................................................................
export async function create(url, params) {
  try {
    const response = await config.post(url, params);
    return response.data.data;
  } catch (error) {
    return error;
  }
}

//.....................................................................
// call  get all data api.......
// this function will be used to get data from all tables
//.....................................................................
export async function getAll(url) {
  try {
    return await config.get(url);
  } catch (error) {
    return error;
  }
}

//.....................................................................
// MULTIPLE GET.......
// this function will be used for multiple request
//.....................................................................
export async function multipleRequest(url = []) {
  try {
    //console.log(url)
    return await axios.all(url);
  } catch (error) {
    return error;
  }
}

//.....................................................................
// GET BY ID.......
// this function will get data by id from the database
//.....................................................................
export async function getByID(url) {
  try {
    return await config.get(url);
  } catch (error) {
    return error;
  }
}

//.....................................................................
// UPDATE DATA.......
// this function will update data from any table
//.....................................................................
export async function update(url, params) {
  try {
    //console.log('url==', url)
    return await config.patch(url, params);
  } catch (error) {
    return error;
  }
}

//.....................................................................
// DELETE DATA.......
// this function will delete data from any table
//.....................................................................
export async function remove(url) {
  try {
    //console.log('url===', url)
    return await config.delete(url);
  } catch (error) {
    return error;
  }
}
