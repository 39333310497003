import { createSlice } from "@reduxjs/toolkit";

/**
 * .................................................................
 * MANAGE  JOBS TASKS
 * .................................................................
 *
 * This is a module that will manage  job tasks
 *
 * FEATURES
 *
 * 1.add job tasks
 * 2.update job tasks
 * 3.delete job  tasks
 *.................................................................
 */
const initialState = {
  columns: [],
  maitenance: [],
  maintenaceURL: "workshop/workshopsite?",
  isLoading: true,
  openModal: false,
  record: {},
};

const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducer: {
    toggleSideBar: (state, action) => {
      state.sidebarShow = !state.sidebarShow;
    },
  },
  // extraReducers: {
  //   [creatrecod.fulfilled]: (state, { payload }) => {},
  //   [getrecord.fulfilled]: (state, { payload }) => {},
  //   [get_data_by_id.fulfilled]: (state, { payload }) => {},
  //   [deleterecord.fulfilled]: (state, { payload }) => {},
  //   [updaterecord.fulfilled]: (state, { payload }) => {},
  // },
});

export const tasksActions = tasksSlice.actions;
export default tasksSlice.reducer;
