import { createSlice } from "@reduxjs/toolkit";
import {
  creatrecod,
  getrecord,
  get_data_by_id,
  deleterecord,
  updaterecord,
} from "../functions/invetory.actions";

/**
 * .................................................................
 * MANAGE INVENTORY
 * .................................................................
 *
 * This is a module that will manage inventory
 *
 * FEATURES
 *
 * 1.add factory inventory, add workshop inventory,
 * 2.update factory inventory, update workshop inventory
 * 3.delete workshop inventory, delete workshop inventory
 * 4.inventory planned mantainance
 * 5.inventory replacements
 *.................................................................
 */

const initialState = {
  columns: [],
  factory: { url: "" },
  workshop: { url: "" },
  status: {
    url: "workshop/workshopsite?",
    data: [],
    isLoading: true,
    record: {},
  },
  group: {
    url: "workshop/workshopsite?",
    data: [],
    isLoading: true,
    record: {},
  },
  types: {
    url: "workshop/workshopsite?",
    data: [],
    isLoading: true,
    record: {},
  },
  company: {
    url: "workshop/workshopsite?",
    data: [],
    isLoading: true,
    record: {},
  },
  maker: {
    url: "workshop/workshopsite?",
    data: [],
    isLoading: true,
    record: {},
  },
  openModal: false,
  record: {},
};

const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducer: {
    toggleSideBar: (state, action) => {
      state.sidebarShow = !state.sidebarShow;
    },
  },
  extraReducers: {
    [creatrecod.fulfilled]: (state, { payload }) => {},
    [getrecord.fulfilled]: (state, { payload }) => {},
    [get_data_by_id.fulfilled]: (state, { payload }) => {},
    [deleterecord.fulfilled]: (state, { payload }) => {},
    [updaterecord.fulfilled]: (state, { payload }) => {},
  },
});

export const inventoryActions = inventorySlice.actions;
export default inventorySlice.reducer;
