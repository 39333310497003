import { createSlice } from "@reduxjs/toolkit";
import {
  creatrecod,
  getwithparams,
  getrecord,
  get_data_by_id,
  deleterecord,
  updaterecord,
} from "../functions/section.actions";

import { getwithparams as getUsers } from "../functions/users.actions";

/**
 * .................................................................
 * CREATE SECTIONS
 * .................................................................
 *
 * This is a module that will manage factory and workshop sections
 *
 * FEATURES
 *
 * 1.add factory section, add workshop section,
 * 2.update factory section, update workshop section
 * 3.delete workshop section, delete workshop section
 * 4.every facroy must have a supervisor
 *.................................................................
 */

const initialState = {
  sections: [],
  users: [],
  isLoading: true,
  isSaved: false,
  isDeleted: false,
  isUpdated: false,
  errorMessage: "",
  openModal: false,
  record: {},
};

//.....................................................................
// GET DATA
//.....................................................................

const sectionSlice = createSlice({
  name: "sections",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getrecord.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getrecord.fulfilled, (state, { payload }) => {
      state.status = false;
      state.sections.push(payload);
    });

    builder.addCase(getrecord.rejected, (state, { payload }) => {
      state.errorMessage = payload;
    });

    builder.addCase(getUsers.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.users.push(payload);
      console.log(payload);
    });

    // builder.addCase(getUsers.pending, (state, { payload }) => {
    //   console.log(payload);
    //   state.errorMessage = payload;
    // });

    // [getrecord.fulfilled]: (state, { payload }) => {
    //   state.status = "succeeded";
    //   console.log(payload);
    //   state.data.push(payload);
    // },
    // [getrecord.rejected]: (state) => {
    //   state.status = "failed";
    // },
    //   [creatrecod.fulfilled]: (state, { payload }) => {
    //     state.record.data = payload;
    //   },

    //   [getUsers.fulfilled]

    //   [get_data_by_id.fulfilled]: (state, { payload }) => {},
    //   [deleterecord.fulfilled]: (state, { payload }) => {},
    //   [updaterecord.fulfilled]: (state, { payload }) => {},
  },
});

export const sectionActions = sectionSlice.actions;
export default sectionSlice.reducer;
