import { createAsyncThunk } from "@reduxjs/toolkit";
import { create, update, getAll, remove, getByID } from "./API";

/**
 * .................................................................
 * MANAGE APPLICATION MODEL
 * .................................................................
 *
 * This is async model of our application that is responsible for
 * communiocating with the restApi
 *
 * FEATURES
 *
 * 1.create model,
 * 2.update model
 * 3.delete model
 *.................................................................
 */

//.....................................................................
// CREATE DATA
//.....................................................................
export const creatrecod = createAsyncThunk(
  "storeroom/save",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = create(params.url, params.record);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//.....................................................................
// GET DATA
//.....................................................................
export const getrecord = createAsyncThunk(
  "storeroom/getrecord",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = getAll(params.url);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//.....................................................................
// GET DATA BY ID
//.....................................................................
export const get_data_by_id = createAsyncThunk(
  "storeroom/specific",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = getByID(params.url);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//.....................................................................
// UPDATE DATA
//.....................................................................
export const updaterecord = createAsyncThunk(
  "storeroom/update",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = update(params.url, params.record);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//.....................................................................
// DELETE DATA
//.....................................................................
export const deleterecord = createAsyncThunk(
  "storeroom/delete",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = remove(params.url);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
