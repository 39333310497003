import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebarShow: true,
};

const uiSlice = createSlice({
  name: "app_ui",
  initialState,
  reducers: {
    toggleSideBar: (state, action) => {
      state.sidebarShow = state.sidebarShow ? false : true;
    },
  },
});

export const { toggleSideBar } = uiSlice.actions;
export default uiSlice.reducer;
