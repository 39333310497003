import { createSlice } from "@reduxjs/toolkit";
import {
  creatrecod,
  getrecord,
  get_data_by_id,
  deleterecord,
  updaterecord,
} from "../functions/jobs.actions";

/**
 * .................................................................
 * MANAGE  FACTORY AND WORKSHOP JOBS
 * .................................................................
 *
 * This is a module that will manage jobs
 *
 * FEATURES
 *
 * 1.add factory jobs, add workshop jobs,
 * 2.update factory jobs, update workshop jobs
 * 3.delete workshop jobs, delete workshop jobs
 * 4.manage jobs tasks
 * 5.manage jobs
 *.................................................................
 */

const initialState = {
  columns: [],
  factoryInventory: [],
  workshopInventory: [],
  worshopURL: "workshop/workshopsite?",
  factoryURL: "factory/factory_sections?",
  isLoading: true,
  openModal: false,
  record: {},
};

const jobSlice = createSlice({
  name: "jobs",
  initialState,
  reducer: {
    toggleSideBar: (state, action) => {
      state.sidebarShow = !state.sidebarShow;
    },
  },

  extraReducers: {
    [creatrecod.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getrecord.fulfilled]: (state, { payload }) => {},
    [get_data_by_id.fulfilled]: (state, { payload }) => {},
    [deleterecord.fulfilled]: (state, { payload }) => {},
    [updaterecord.fulfilled]: (state, { payload }) => {},
  },
});

export const jobActions = jobSlice.actions;
export default jobSlice.reducer;
