import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { create, update, getAll, remove, getByID } from "../functions/API";

/**
 * .................................................................
 * MANAGE APPLICATION MODEL
 * .................................................................
 *
 * This is async model of our application that is responsible for
 * communiocating with the restApi
 *
 * FEATURES
 *
 * 1.create model,
 * 2.update model
 * 3.delete model
 *.................................................................
 */

//.....................................................................
// CREATE DATA
//.....................................................................
export const creatrecod = createAsyncThunk(
  "users/save",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = create(params.url, params.record);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//.....................................................................
// GET DATA WITH PARAMS
//.....................................................................
export const getwithparams = createAsyncThunk(
  "users/getwithparams",
  (params, { rejectWithValue }) => {
    try {
      const response = create(params.url, params.record);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//.....................................................................
// GET DATA
//.....................................................................
export const getrecord = createAsyncThunk(
  "users/getrecord",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = getAll(params.url);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//.....................................................................
// GET DATA BY ID
//.....................................................................
export const get_data_by_id = createAsyncThunk(
  "users/specific",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = getByID(params.url);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//.....................................................................
// UPDATE DATA
//.....................................................................
export const updaterecord = createAsyncThunk(
  "users/update",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = update(params.url, params.record);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//.....................................................................
// DELETE DATA
//.....................................................................
export const deleterecord = createAsyncThunk(
  "users/delete",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = remove(params.url);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/**
 * .................................................................
 * MANAGE  APPLICATION USERS
 * .................................................................
 *
 * This is a module that will manage jobs
 *
 * FEATURES
 *
 * 1.add application users,
 * 2.update application users
 * 3.delete application users
 *.................................................................
 */
const userSlice = createSlice({
  name: "users",
  initialState: {
    data: [],
    isLoading: true,
    isSaved: false,
    isDeleted: false,
    isUpdated: false,
    openModal: false,
    record: {},
  },

  reducers: {
    handleInputChange: (state, { payload }) => {
      state.record[payload.name] = payload.value;
      console.log(state.record);
    },
  },
  extraReducers: {
    [creatrecod.fulfilled]: (state, { payload }) => {
      state.record = payload;
      state.isLoading = false;
    },
    [getrecord.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.isLoading = false;
    },
    [getwithparams.fulfilled]: (state, { payload }) => {
      state.isLoading = false;

      console.log(payload);
      state.data = payload;
    },
    [get_data_by_id.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.isLoading = false;
    },
    [deleterecord.fulfilled]: (state, { payload }) => {
      state.isDeleted = true;
      state.isLoading = false;
    },
    [updaterecord.fulfilled]: (state, { payload }) => {
      state.isUpdated = true;
      state.isLoading = false;
    },
  },
});

export const { handleInputChange } = userSlice.actions;
export default userSlice.reducer;
