import axios from "axios";
import { getSession } from "./useSessionStorage";

// export default axios.create({
//   baseURL: `http://localhost:2000/api/`,
// });

// export default axios.create({
//   baseURL: `https://tucoswa.esnaufis.org/api/`,
// });

export default axios.create({
  baseURL: `https://svtechworld.net/api/`,
});
