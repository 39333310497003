import { createSlice } from "@reduxjs/toolkit";
import {
  creatrecod,
  getrecord,
  get_data_by_id,
  deleterecord,
  updaterecord,
} from "../functions/storeroom.actions";
/**
 * .................................................................
 * MANAGE  STOREROOM PARTS
 * .................................................................
 *
 * This is a module that will manage  storeroom parts
 *
 * FEATURES
 *
 * 1.add storeroom parts
 * 2.update storeroom parts
 * 3.delete storeroom parts
 *.................................................................
 */
const initialState = {
  columns: [],
  maitenance: [],
  maintenaceURL: "workshop/workshopsite?",
  isLoading: true,
  openModal: false,
  record: {},
};

const storeroomSlice = createSlice({
  name: "storeroom",
  initialState,
  reducer: {
    toggleSideBar: (state, action) => {
      state.sidebarShow = !state.sidebarShow;
    },
  },
  extraReducers: {
    [creatrecod.fulfilled]: (state, { payload }) => {},
    [getrecord.fulfilled]: (state, { payload }) => {},
    [get_data_by_id.fulfilled]: (state, { payload }) => {},
    [deleterecord.fulfilled]: (state, { payload }) => {},
    [updaterecord.fulfilled]: (state, { payload }) => {},
  },
});

export const storeroomActions = storeroomSlice.actions;
export default storeroomSlice.reducer;
